import { FunctionComponent, useEffect, useState } from "react";
import cookie from 'react-cookies';
import FeedbackDetails from "./components/feedbacks/FeedbackDetails";
import { FeedbackQuestion } from "./models/FeedbackQuestion";
import I18nUtils from "./I18n/I18nUtils";
import { TR_FEEBBACK_GIVEN, TR_GENERIC_ERROR, TR_INTERNAL_SERVER_ERROR } from "./I18n/constants";
import { ROUTE_FEEDBACK_NEW, ROUTE_FEEDBACK_QUESTIONS } from "./routes/routes";

const GiveFeedbackAction: FunctionComponent = () => {
    const [questions, setQuestions] = useState<FeedbackQuestion[]>()

    const [responseMessage, setResponseMessage] = useState<string>(I18nUtils.tr(TR_FEEBBACK_GIVEN))
    const [showResponseMessage, setShowResponseMessage] = useState<boolean>(false)
    const [isError, setIsError] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)

    const handleResponse = (data: any) => {
        setIsError(!data.success)
        if (!data.success) {
            if (data.code === 500)
                setResponseMessage(I18nUtils.tr(TR_INTERNAL_SERVER_ERROR))
            else
                setResponseMessage(I18nUtils.tr(TR_GENERIC_ERROR))
        }
        setShowResponseMessage(true)
        setTimeout(() => {
            setShowResponseMessage(false)
        }, 10000)
    }

    const translate = (message: string) => {
        return I18nUtils.tr(message)
    }

    useEffect(() => {
        if (!questions) {
            const headers = { Authorization: 'Bearer ' + cookie.load('AUTH_TOKEN') }
            fetch(process.env.REACT_APP_URL + I18nUtils.getLocale() + ROUTE_FEEDBACK_QUESTIONS, { headers })
                .then(response => response.json())
                .then(data => { setQuestions(data.questions) })
        }
    }, [questions])

    const createFeedback = (payload: any) => {
        if (payload.relations.answers.length > 0) {
            const requestOptions = {
                body: JSON.stringify(payload),
                method: 'POST',
                headers: { Authorization: 'Bearer ' + cookie.load('AUTH_TOKEN') }
            }

            setLoading(true)
            fetch(process.env.REACT_APP_URL +  I18nUtils.getLocale() + ROUTE_FEEDBACK_NEW, requestOptions)
                .then(response => response.json())
                .then(data => { handleResponse(data); setLoading(false) })
        }
    }

    return <FeedbackDetails 
                questions={questions} 
                createFeedback={createFeedback} 
                responseMessage={responseMessage} 
                showResponseMessage={showResponseMessage} 
                isError={isError} 
                loading={loading} 
                translate={translate}/>
}

export default GiveFeedbackAction
