import { FunctionComponent } from "react";
import { TR_ACTIONS, TR_DATE, TR_DELIVERED, TR_ESTABLISHMENT, TR_NO_ORDERS, TR_TOTAL_COST, TR_TOTAL_COST_DISCOUNT } from "../../I18n/constants";
import { Establishment } from "../../models/Establishment";
import OrderDetails from "./OrderDetails";
import OrderInfo from "./OrderInfo";
import './Orders.css'

interface OrderTableProps {
    establishments: Establishment[] | undefined,
    repeatOrder: (id: string) => void,
    responseMessage: string,
    showResponseMessage: boolean,
    isError: boolean,
    loading: boolean,
    translate: (message: string) => string
}

const OrderTable: FunctionComponent<OrderTableProps> = (
    {
        establishments,
        repeatOrder,
        responseMessage,
        showResponseMessage,
        isError,
        loading,
        translate
    }
) => {

    if (!establishments)
        return (
            <div className="loadingView"><div className="align-middle text-costcenter"><div className="css-cgolnw-style-Loader"></div></div></div>
        )

    if (establishments.length === 0)
        return (
            <div className="repeat-container">
                <div className="col-md-12">
                <div className="card">
                    <div className="card-body">
                    {translate(TR_NO_ORDERS)}
                    </div>
                </div>
                </div>
            </div>
        )

    const renderList = () => {
        if (establishments.length === 1)
            return <OrderInfo
                establishment={establishments[0]}
                repeatOrder={repeatOrder}
                translate={translate} />
        else {
            return (
                <div className="repeat-container">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead className="text-primary">
                                            <tr>
                                                <th>{translate(TR_DATE)}</th>
                                                <th>{translate(TR_DELIVERED)}</th>
                                                <th>{translate(TR_TOTAL_COST)}</th>
                                                <th>{translate(TR_TOTAL_COST_DISCOUNT)}</th>
                                                {renderEstablishmentName()}
                                                <th>{translate(TR_ACTIONS)}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {establishmentsRendered}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

    }

    const establishmentsRendered = establishments.map(establishment => {
        const lastOrder = establishment.orders
            .find(order => {
                return order.oid === establishment.orders
                    .map(order => order.oid)
                    .reduce(function (prev, current) {
                        return (parseInt(prev) > parseInt(current)) ? prev : current
                    })
            })

        return <OrderDetails
            order={lastOrder}
            establishment={establishment}
            repeatOrder={repeatOrder}
            renderEstablishment={establishments.length > 1}
            translate={translate} />
    })

    const renderEstablishmentName = () => {
        if (establishments.length > 1) return <th>{translate(TR_ESTABLISHMENT)}</th>
    }

    const alertType = () => { return isError ? 'danger' : 'success' }

    return (
        <>
            <div className={"alert alert-" + alertType() + " fade show"} hidden={!showResponseMessage}>{responseMessage}</div>
            {renderList()}
            <div hidden={!loading} className="loadingView"><div className="align-middle text-costcenter"><div className="css-cgolnw-style-Loader"></div></div></div>
        </>
    )
}

export default OrderTable