import { FunctionComponent } from "react";
import { FeedbackQuestion } from "../../models/FeedbackQuestion";

interface ShortQuestionProps {
    question: FeedbackQuestion
}

const ShortQuestion: FunctionComponent<ShortQuestionProps> = 
(
    {
        question
    }
) => {
    return (
        <div className="question-group">
            <div>
                <h5>{question.question}</h5>
            </div>
            <div className="answer">
                <textarea name="response" id={question.oid} cols={2} rows={3}></textarea>
            </div>
        </div>
    )
}

export default ShortQuestion