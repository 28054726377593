import React from 'react';
import { FormRenderData, Module } from '@cuatroochenta/co-admin-react-library';
import RepeatOrderAction from './RepeatOrderAction';
import I18nUtils from './I18n/I18nUtils';
import { ROUTE_DOWNLOAD } from './routes/routes';
import cookie from 'react-cookies';

import '@cuatroochenta/co-admin-react-library/lib/index.css';
import SoldProductsAction from './SoldProductsAction';
import GiveFeedbackAction from './GiveFeedbackAction';

interface Filters {
    [dynamic: string]: string
}

export interface FiltersType {
    [dynamic: string]: string
}

class App extends React.Component {

    private donwload = (entity: string, filters: FiltersType, orderby: string, order: string) => {
        const requestOptions = {
            method: 'POST',
            headers:  { Authorization: 'Bearer ' + cookie.load('AUTH_TOKEN') },
            body: JSON.stringify({
                filters: filters,
                order: order,
                orderby:orderby
            })
        }

        fetch(process.env.REACT_APP_URL + I18nUtils.getLocale() + ROUTE_DOWNLOAD.replace(':entity', entity), requestOptions)
                .then(response => response.json())
                .then(data => window.open(data.data.pdf))
    }

    private beforeFormView = (entity: string, type: string, data: FormRenderData): React.ReactNode => {
        return null;
    };

    private afterFormView = (entity: string, type: string, data: FormRenderData): React.ReactNode => {
        return null;
    };

    private customButtonsList = (entity: string, filters: Filters, orderby: string, order: string): React.ReactNode => {
        if (entity === 'orders' || entity === 'product') {
            return (
                <button
                    className="btn btn-dark mt-4 pull-right"
                    onClick={() => this.donwload(entity, filters, orderby, order) }
                >
                    <i className='fa fa-broom d-inline-block d-sm-none'/>
                    <span className="d-none d-sm-inline-block">Descargar</span>
                </button>
            )
        }
        return null;
    };

    public render() {
        return (
            <Module
                actions={[
                    { name: 'RepeatLastOrder', element: <RepeatOrderAction/> },
                    { name: 'ListSoldProducts', element: <SoldProductsAction/> },
                    { name: 'GiveFeedback', element: <GiveFeedbackAction/> }
                ]}
                beforeFormView={this.beforeFormView}
                afterFormView={this.afterFormView}
                customButtonsList={this.customButtonsList}
            />
        );
    }

}

export default App;
