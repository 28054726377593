import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { TR_BUYED, TR_CATEGORY, TR_MEASURE, TR_NO_PRODUCTS, TR_PRODUCT, TR_TIME_BUYED, TR_TIMES_BUYED } from "../../I18n/constants";
import { SoldProducts } from "../../models/SoldProducts";
import { ROUTE_PRODUCT_VIEW } from "../../routes/routes";
import './Products.css'

interface ProductListProps {
    soldProducts: SoldProducts[] | undefined,
    translate: (message: string) => string
}

const ProductList: FunctionComponent<ProductListProps> = (
    {
        soldProducts,
        translate
    }
) => {

    if (!soldProducts)
        return (
            <div className="loadingView"><div className="align-middle text-costcenter"><div className="css-cgolnw-style-Loader"></div></div></div>
        )

    if (soldProducts.length === 0) {
        return (
            <div className="col-md-12">
                <div className="card">
                    <div className="card-body">
                        {translate(TR_NO_PRODUCTS)}
                    </div>
                </div>
            </div>
        )
    }

    const productsRendered = soldProducts.map(soldProduct => {
        return (
            <Link to={ROUTE_PRODUCT_VIEW.replace(':id', soldProduct[0].product.oid)}>
                <div className="product-container">
                    <div className="product-image">
                        <img src={soldProduct[0].product.defaultimage} alt="Product" />
                    </div>
                    <div className="product-overlay">
                        <div className="text">
                            <p>
                                {translate(TR_PRODUCT)}: {soldProduct[0].product.name}
                                <br />
                                {translate(TR_CATEGORY)}: {soldProduct[0].product.category.name}
                                <br />
                                {translate(TR_MEASURE)}: {soldProduct[0].measure.name}
                                <br />
                                {translate(TR_BUYED)} {soldProduct.times_buyed} {soldProduct.times_buyed === "1" ? translate(TR_TIME_BUYED) : translate(TR_TIMES_BUYED)}
                            </p>
                        </div>
                    </div>
                </div>
            </Link>
        )
    })

    return (
        <div className="list-container">
            {productsRendered}
        </div>
    )

}

export default ProductList
