import { FunctionComponent } from "react";
import { TR_NO_FEEDBACKS, TR_SEND } from "../../I18n/constants";
import { FeedbackAnswer } from "../../models/FeedbackAnswer";
import { FeedbackQuestion, QuestionTypes } from "../../models/FeedbackQuestion";
import './Feedbacks.css'
import LikertQuestion from "./LikertQuestion";
import LongQuestion from "./LongQuestion";
import ShortQuestion from "./ShortQuestion";

interface FeedbackDetailsModel {
    questions: FeedbackQuestion[] | undefined,
    createFeedback: (payload: any) => void,
    responseMessage: string,
    showResponseMessage: boolean,
    isError: boolean,
    loading: boolean,
    translate: (message: string) => string
}

const FeedbackDetails: FunctionComponent<FeedbackDetailsModel> =
    (
        {
            questions,
            createFeedback,
            responseMessage,
            showResponseMessage,
            isError,
            loading,
            translate
        }
    ) => {

        if (!questions)
            return (
                <div className="loadingView"><div className="align-middle text-costcenter"><div className="css-cgolnw-style-Loader"></div></div></div>
            )

        if (questions.length === 0)
            return (
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">
                            {translate(TR_NO_FEEDBACKS)}
                        </div>
                    </div>
                </div>
            )

        const onSubmit = () => {
            const answers: FeedbackAnswer[] = []
            questions.map(question => {
                const answer = document.getElementById(question.oid) as HTMLInputElement
                const value = answer.value
                if (value !== "") {
                    answers.push({answer: value, feedbackQuestion: question.oid})
                }
                answer.value = ''
            })

            const payload = {
                entity: {},
                relations: {
                    answers: answers
                }
            }

            createFeedback(payload)

        }

        const questionsRendered = questions.map(question => {
            switch (question.questionTypes.name) {
                case QuestionTypes.SHORT:
                    return <ShortQuestion question={question} />
                case QuestionTypes.LONG:
                    return <LongQuestion question={question} />
                case QuestionTypes.LIKERT:
                    return <LikertQuestion question={question} />
            }
        })

        const alertType = () => {
            return isError ? 'danger' : 'success'
        }

        return (
            <>
                <div className={"alert alert-" + alertType() + " fade show"} hidden={!showResponseMessage}>{responseMessage}</div>
                <div className="feedback-container">
                    <div className="questions-data">
                        {questionsRendered}
                    </div>
                    <button onClick={onSubmit} className="btn btn-custom" style={{ backgroundColor: 'rgb(81, 203, 206)' }}>{translate(TR_SEND)}</button>
                </div>
                <div hidden={!loading} className="loadingView"><div className="align-middle text-costcenter"><div className="css-cgolnw-style-Loader"></div></div></div>
            </>
        )
    }

export default FeedbackDetails