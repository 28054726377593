import React, { FunctionComponent, useEffect, useState } from "react";
import cookie from 'react-cookies';
import ProductList from "./components/products/ProductList";
import { SoldProducts } from "./models/SoldProducts";
import I18nUtils from "./I18n/I18nUtils";
import { ROUTE_PRODUCTS_TOP_SOLD } from "./routes/routes";

const SoldProductsAction: FunctionComponent = () => {
    const [soldProducts, setSoldProducts] = useState<SoldProducts[]>()

    const translate = (message: string) => {
        return I18nUtils.tr(message)
    }

    useEffect(() => {
        if (!soldProducts) {
            const headers = { Authorization: 'Bearer ' + cookie.load('AUTH_TOKEN') }
            fetch(process.env.REACT_APP_URL + I18nUtils.getLocale() + ROUTE_PRODUCTS_TOP_SOLD, { headers })
                .then(response => response.json())
                .then(data => { setSoldProducts(data.soldProducts)})
        }

    }, [soldProducts])

    return <ProductList soldProducts={soldProducts} translate={translate}/>
}

export default SoldProductsAction