import moment from "moment";
import { useState } from "react";
import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { TR_CURRENCY, TR_ESTABLISHMENT_NO_ORDERS, TR_NO, TR_YES } from "../../I18n/constants";
import { Establishment } from "../../models/Establishment";
import { Orders } from "../../models/Orders";
import { ROUTE_ORDER_VIEW } from "../../routes/routes";
import OrderLineDetails from "./OderLineDetails";

interface OrderDetailsProps {
    order: Orders |undefined,
    establishment: Establishment,
    repeatOrder: (id: string) => void,
    renderEstablishment: boolean,
    translate: (message: string) => string
}

const OrderDetails: FunctionComponent<OrderDetailsProps> = (
    {
        order,
        establishment,
        repeatOrder,
        renderEstablishment,
        translate
    }
) => {

    const [show, setShow] = useState<boolean>(false)

    if (!order) 
        return <div><tr><td colSpan={6}>{establishment.client.cif + " " + establishment.code + translate(TR_ESTABLISHMENT_NO_ORDERS)}</td></tr></div>

    let totalcost: number = 0
    order.orderLines.forEach(orderLine => {
        totalcost += orderLine.quantity * orderLine.measureCost.cost * ((100-orderLine.product.discount) / 100)
    })
    let totalcostafterdiscount = 0
    totalcostafterdiscount = totalcost * ((100-establishment.client.discount) / 100)

    const orderLinesRendered = order.orderLines.map(orderLine => {
        return <OrderLineDetails orderLine={orderLine} translate={translate}/>
    })

    const renderEstablishmentName = () => {
        if (renderEstablishment)
            return <td className="column-field-pkint">{establishment.client.cif + ' ' + establishment.code}</td>
    }

    return (
        <>
            <tr>
                <td className="column-field-datetime">{moment(order.date).format('DD/MM/YYYY HH:mm:ss')}</td>
                <td className="column-field-boolean">{order.delivered ? translate(TR_YES) : translate(TR_NO)}</td>
                <td className="column-field-float">{totalcost.toPrecision(3) + translate(TR_CURRENCY)}</td>
                <td className="column-field-float">{totalcostafterdiscount.toPrecision(3) + translate(TR_CURRENCY)}</td>
                {renderEstablishmentName()}
                <td className="list-actions-column">
                    <Link to={ROUTE_ORDER_VIEW.replace(':id', order.oid)}>
                        <i className="fa fa-eye" style={{cursor: 'pointer', color: 'rgb(78, 108, 115)'}}></i>
                    </Link>
                    <a>
                        <i className="fa fa-bars" onClick={() => setShow(!show)} style={{cursor: 'pointer', color: 'rgb(78, 108, 115)'}}></i>
                    </a>
                    <a>
                        <i className="fa fa-clone" style={{cursor: 'pointer', color: 'rgb(78, 108, 115)'}} onClick={() => repeatOrder(order.oid)}></i>
                    </a>
                </td>
            </tr>
            <tr hidden={!show}>
                <td colSpan={6}>
                    <div className="col-md-12">
                        <div className="row">
                            {orderLinesRendered}
                        </div>
                    </div>
                </td>
            </tr>
        </>
    )
}

export default OrderDetails