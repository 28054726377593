import { FunctionComponent } from "react";
import { TR_CURRENCY, TR_DISCOUNT, TR_LINE_COST, TR_MEASURE_COST, TR_PRODUCT, TR_QUANTITY } from "../../I18n/constants";
import { OrderLines } from "../../models/OrderLines";

interface OrderLineDetailsProps {
    orderLine: OrderLines,
    translate: (message: string) => string
}

const OrderLineDetails: FunctionComponent<OrderLineDetailsProps> = (
    {
        orderLine,
        translate
    }
) => {

    return (
        <div className="col-md-12">
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="form-group"><label>{translate(TR_PRODUCT)}</label>
                                <input type="text" className="form-control" disabled={true} 
                                    value={orderLine.product.name} />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group"><label>{translate(TR_MEASURE_COST)}</label>
                                <input type="text" className="form-control" disabled={true} 
                                    value={orderLine.measureCost.cost + translate(TR_CURRENCY) + " / " + orderLine.measureCost.measure.name} />
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="form-group"><label>{translate(TR_DISCOUNT)}</label>
                                <input type="text" className="form-control" disabled={true} 
                                    value={orderLine.product.discount + " %"} />
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="form-group"><label>{translate(TR_QUANTITY)}</label>
                                <input type="text" className="form-control" disabled={true} 
                                    value={orderLine.quantity} />
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="form-group"><label>{translate(TR_LINE_COST)}</label>
                                <input type="text" className="form-control" disabled={true} 
                                    value={(orderLine.quantity * orderLine.measureCost.cost * ((100-orderLine.product.discount) / 100)).toPrecision(3) + translate(TR_CURRENCY)} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OrderLineDetails