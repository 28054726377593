import React, { FunctionComponent, useEffect, useState } from 'react';
import cookie from 'react-cookies';
import { Establishment } from './models/Establishment';
import OrderTable from './components/orders/OrderTable';
import I18nUtils from './I18n/I18nUtils';
import { TR_GENERIC_ERROR, TR_INTERNAL_SERVER_ERROR } from './I18n/constants';
import { ROUTE_ORDERS_DUPLICATE, ROUTE_REPEAT_ORDER } from './routes/routes';

const RepeatOrderAction: FunctionComponent = () => {

    const [establishments, setEstablishments] = useState<Establishment[]>()
    const [responseMessage, setResponseMessage] = useState<string>('')
    const [showResponseMessage, setShowResponseMessage] = useState<boolean>(false)
    const [isError, setIsError] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)

    const handleResponse = (data: any) => {
        setIsError(!data.success)
        if (!data.success) {
            if (data.code === 500)
                setResponseMessage(I18nUtils.tr(TR_INTERNAL_SERVER_ERROR))
            else
                setResponseMessage(I18nUtils.tr(TR_GENERIC_ERROR))
        }
        else {
            console.log(data.data.message)
            setResponseMessage(data.data.message)
        }
        setShowResponseMessage(true)
        setTimeout(() => {
            setShowResponseMessage(false)
        }, 10000)
    }

    const translate = (message: string) => {
        return I18nUtils.tr(message)
    }

    useEffect(() => {
        if (!establishments) {
            const headers = { Authorization: 'Bearer ' + cookie.load('AUTH_TOKEN') }
            fetch(process.env.REACT_APP_URL + I18nUtils.getLocale() + ROUTE_ORDERS_DUPLICATE, { headers })
                .then(response => response.json())
                .then(data => { setEstablishments(data.establishments)})
        }

    }, [establishments])

    const repeatOrder = (id: string) => {
        const requestOptions = {
            method: 'POST',
            headers:  { Authorization: 'Bearer ' + cookie.load('AUTH_TOKEN') }
        }

        setLoading(true)
        fetch(process.env.REACT_APP_URL + I18nUtils.getLocale() + ROUTE_REPEAT_ORDER.replace(':id', id), requestOptions)
        .then(response => response.json())
            .then(data => { handleResponse(data); setLoading(false) })
                
    }

    return <OrderTable 
                establishments={establishments}
                repeatOrder={repeatOrder}
                responseMessage={responseMessage} 
                showResponseMessage={showResponseMessage} 
                isError={isError} 
                loading={loading}
                translate={translate}/>
}

export default RepeatOrderAction;

