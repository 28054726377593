export const TR_INTERNAL_SERVER_ERROR = 'TR_INTERNAL_SERVER_ERROR';
export const TR_GENERIC_ERROR = 'TR_GENERIC_ERROR';
export const TR_ACTIONS = 'TR_ACTIONS';
export const TR_FEEBBACK_GIVEN = 'TR_FEEBBACK_GIVEN'
export const TR_NO_FEEDBACKS = 'TR_NO_FEEDBACKS'
export const TR_SEND = 'TR_SEND'
export const TR_NO_ORDERS = 'TR_NO_ORDERS'
export const TR_ESTABLISHMENT = 'TR_ESTABLISHMENT'
export const TR_DATE = 'TR_DATE'
export const TR_DELIVERED = 'TR_DELIVERED'
export const TR_TOTAL_COST = 'TR_TOTAL_COST'
export const TR_TOTAL_COST_DISCOUNT = 'TR_TOTAL_COST_DISCOUNT'
export const TR_PRODUCT = 'TR_PRODUCT'
export const TR_MEASURE_COST = 'TR_MEASURE_COST'
export const TR_DISCOUNT = 'TR_DISCOUNT'
export const TR_QUANTITY = 'TR_QUANTITY'
export const TR_LINE_COST = 'TR_LINE_COST'
export const TR_CATEGORY = 'TR_CATEGORY'
export const TR_MEASURE = 'TR_MEASURE'
export const TR_BUYED = 'TR_BUYED'
export const TR_TIME_BUYED = 'TR_TIME'
export const TR_TIMES_BUYED = 'TR_TIMES'
export const TR_NO_PRODUCTS = 'TR_NO_PRODUCTS'
export const TR_ESTABLISHMENT_NO_ORDERS = 'TR_ESTABLISHMENT_NO_ORDERS'
export const TR_REPEAT_ORDER = 'TR_REPEAT_ORDER'
export const TR_ORDER_LINES = 'TR_ORDER_LINES'
export const TR_YES = 'TR_YES'
export const TR_NO = 'TR_NO'
export const TR_CURRENCY = 'TR_CURRENCY'