import { FunctionComponent } from "react";
import { FeedbackQuestion } from "../../models/FeedbackQuestion";

interface LongQuestionProps {
    question: FeedbackQuestion
}

const LongQuestion: FunctionComponent<LongQuestionProps> = 
(
    {
        question
    }
) => {
    return (
        <div className="question-group">
            <div>
                <h5>{question.question}</h5>
            </div>
            <div className="answer">
                <textarea name="response" id={question.oid} cols={2} rows={6} ></textarea>
            </div>
        </div>
    )
}

export default LongQuestion