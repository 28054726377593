import { FunctionComponent, useState } from "react";
import { FeedbackQuestion } from "../../models/FeedbackQuestion";

interface LikertQuestionProps {
    question: FeedbackQuestion
}

const LikertQuestion: FunctionComponent<LikertQuestionProps> =
    (
        {
            question
        }
    ) => {

        const [ localRating, setLocalRating ] = useState<number>(0);
        const [rating, setRating] = useState<number>()

        const setColor = (number: number) => {
            if (rating)
                return localRating >= number ? " hover" : ""
            else
                return ""
        }

        return (
            <div className="question-group">
                <div>
                    <h5>{question.question}</h5>
                </div>
                <div className="answer">
                    <input type="number" hidden={true} value={rating} id={question.oid}/>
                    <div className="rating">
                        <i className={"fa fa-star" + setColor(1)} onMouseEnter={() => setLocalRating(1)} onClick={() => setRating(localRating)} ></i>
                        <i className={"fa fa-star" + setColor(2)} onMouseEnter={() => setLocalRating(2)} onClick={() => setRating(localRating)} ></i>
                        <i className={"fa fa-star" + setColor(3)} onMouseEnter={() => setLocalRating(3)} onClick={() => setRating(localRating)} ></i>
                        <i className={"fa fa-star" + setColor(4)} onMouseEnter={() => setLocalRating(4)} onClick={() => setRating(localRating)} ></i>
                        <i className={"fa fa-star" + setColor(5)} onMouseEnter={() => setLocalRating(5)} onClick={() => setRating(localRating)} ></i>
                    </div>
                </div>
            </div>
        )
    }

export default LikertQuestion