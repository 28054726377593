export interface FeedbackQuestion {
    oid: string,
    question: string,
    questionTypes: {
        oid: string,
        name: QuestionTypes
    }
}

export enum QuestionTypes {
    SHORT = "SHORT",
    LONG = "LONG",
    LIKERT = "LIKERT"
}