import moment from "moment";
import { FunctionComponent } from "react";
import { TR_CURRENCY, TR_DATE, TR_DELIVERED, TR_ESTABLISHMENT_NO_ORDERS, TR_NO, TR_ORDER_LINES, TR_REPEAT_ORDER, TR_TOTAL_COST, TR_TOTAL_COST_DISCOUNT, TR_YES } from "../../I18n/constants";
import { Establishment } from "../../models/Establishment";
import OrderLineDetails from "./OderLineDetails";
import './Orders.css'

interface OrderInfoProps {
    establishment: Establishment,
    repeatOrder: (id: string) => void,
    translate: (message: string) => string
}

const OrderInfo: FunctionComponent<OrderInfoProps> = (
    {
        establishment,
        repeatOrder,
        translate
    }
) => {

    const order = establishment.orders
        .find(order => {
            return order.oid === establishment.orders
                .map(order => order.oid)
                .reduce(function (prev, current) {
                    return (parseInt(prev) > parseInt(current)) ? prev : current
                })
        })

    if (!order)
        return (
            <div className="repeat-container">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">
                        {establishment.client.cif + " " + establishment.code + translate(TR_ESTABLISHMENT_NO_ORDERS)}
                        </div>
                    </div>
                </div>
            </div>
        )

    let totalcost: number = 0
    order.orderLines.forEach(orderLine => {
        totalcost += orderLine.quantity * orderLine.measureCost.cost * ((100 - orderLine.product.discount) / 100)
    })
    let totalcostafterdiscount = 0
    totalcostafterdiscount = totalcost * ((100 - establishment.client.discount) / 100)

    const orderLinesRendered = order.orderLines.map(orderLine => {
        return <OrderLineDetails orderLine={orderLine} translate={translate} />
    })

    return (
        <div className="repeat-container">
            <div className="col-md-12">
                <div className="card container">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="form-group"><label>{translate(TR_DATE)}</label>
                                    <input type="text" className="form-control" disabled={true}
                                        value={moment(order.date).format('DD/MM/YYYY HH:mm:ss')} />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group"><label>{translate(TR_DELIVERED)}</label>
                                    <input type="text" className="form-control" disabled={true}
                                        value={order.delivered ? translate(TR_YES) : translate(TR_NO)} />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group"><label>{translate(TR_TOTAL_COST)}</label>
                                    <input type="text" className="form-control" disabled={true}
                                        value={`${totalcost.toPrecision(3)} ${translate(TR_CURRENCY)}`} />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group"><label>{translate(TR_TOTAL_COST_DISCOUNT)}</label>
                                    <input type="text" className="form-control" disabled={true}
                                        value={`${totalcostafterdiscount.toPrecision(3)} ${translate(TR_CURRENCY)}`} />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <label>{translate(TR_ORDER_LINES)}</label>
                                <div className="row">
                                    {orderLinesRendered}
                                </div>
                            </div>
                        </div>
                    </div>
                    <button className="btn btn-dark mt-4 pull-right" onClick={() => repeatOrder(order.oid)}>
                        <i className='fa fa-broom d-inline-block d-sm-none' />
                        <span className="d-none d-sm-inline-block">{translate(TR_REPEAT_ORDER)}</span>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default OrderInfo